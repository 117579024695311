:root {
    --green: #1ED760;
    --font: 'Varela Round', sans-serif;
    --logo-height: 4.5rem;
}
* {
    position: relative;
}
.green {
    color: var(--green);
}
body {
    font-family: var(--font);
    color: black;
}
.navbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    padding: 0;
}
.navbar .navbar-brand {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
}
.navbar .container-fluid {
    padding-left: 0;
    padding-right: 0;
}
.navbar-toggler {
    color: var(--green);
    background: white;
    margin-right: .5rem;
}
.navbar-toggler .hamburger, .navbar-toggler.collapsed .close {
    color: var(--green);
    display: none;
}
.navbar-toggler.collapsed .hamburger, .navbar-toggler .close {
    color: var(--green);
    display: block;
}
a.nav-link {
    color: rgba(0,0,0,1);
}
a.nav-link.active {
    color: var(--green);
}
.navbar-brand img {
    height: var(--logo-height);
}
.nav-item {
    margin-left: 1rem;
    margin-right: 1rem;
}
.navbar-nav a.btn.btn-south, a.btn.btn-south, .navbar-nav button.btn.btn-south, button.btn.btn-south {
    font-family: var(--font);
    text-transform: uppercase;
    font-weight: 700;
    white-space: nowrap;
}
a.btn.btn-south.btn-trial, button.btn.btn-south.btn-trial {
    background: var(--green);
    color: #fff;
}
a.btn.btn-south.btn-login {
    border: 2px solid var(--green);
    color: var(--green);
}
.feature {
    font-size: 2.7rem;
}
@media screen {
    .feature {
    width: 100%;
    height: calc(100vh - 1.625rem - var(--logo-height));
    overflow: hidden;
}
.feature .background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    filter: brightness(.7);
}
.feature .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}
.feature-container .brand {
    position: absolute;
    right: 2.5rem;
    bottom: 0;
    transform: translateY(30%);
    width: 30%;
}
}
@media print {
.feature .background {
    width: 100%;
    height: 50%;
    object-fit: cover;
    object-position: top center;
    filter: brightness(.7);
}
.feature .content {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}
.feature-container .brand {
    position: absolute;
    right: 2.5rem;
    top: 45%;
    width: 30%;
}
.intro-section {
    width: 90%;
}
}
.feature .text {
    grid-row: 2 / 3;
    margin: auto;
    width: 700px;
    max-width: 100%;
    line-height: 3.5rem;
    padding: 2rem;
}
.feature .next {
    grid-row: 3 / 4;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}
.feature .next:active, .feature .next:hover {
    color: white;
}
.bold {
    font-weight: bolder;
}
section {
    padding: 5rem;
}
h4 {
    font-weight: bolder;
    padding-bottom: 1rem;
}
section p {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.scroll-anchor {
    top: -2rem;
}
.inverted {
    color: white;
}
.background1 {
    background: url("../images/background1.jpg");
    background-size: cover;
    background-position: center center;
}
.background2 {
    background: url("../images/background2.jpg");
    background-size: cover;
    background-position: center center;
}
.how-card {
    margin: 2rem;
    padding: 1.5rem;
    background: #f6f6f6;
    border-radius: 0 20% 0 20%;
    max-width: 18rem;
    width: 33%;
    align-items: center;
}
.how-card img {
    width: 75%;
}
.feature-card {
    margin: 1rem 1rem;
    padding: .5rem;
    border: 1px solid white;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    text-align: left;
}
.feature-card img {
    width: 25%;
    height: 3rem;
    object-fit: contain;
}
.feature-card h6 {
    margin: 0;
}
.integration-text {
    height: 4rem;
}
.integration {
    max-height: 10rem;
    max-width: 10rem;
}
.modules {
    background: #f3f3f0;
}
.modules p {
    padding-top: .25rem;
}
.modules .row {
    gap: 5rem;
}
.module-header {
    color: var(--green);
    margin-bottom: 1.5rem;
}
.module-subheader {
    margin-bottom: 0;
}
.background3 {
    background: black;
}
.contact {
    overflow: hidden;
}
.contact h1 {
    color: var(--green);
}
.contact-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "header header spacer"
        "text form spacer"
        "details form spacer";
}
.contact-grid .header {
    grid-area: header;
}
.contact-grid .text {
    grid-area: text;
}
.contact-grid .form {
    grid-area: form;
}
.contact-grid .form .input-group {
    margin-bottom: 1rem;
}
.contact .image {
    position: absolute;
    right: 0;
    bottom: -10rem;
}
.contact .spacer {
    grid-area: spacer;
}
.contact .details {
    grid-area: details;
}
.contact .details p {
    margin-bottom: 0;
    padding: 0;
}
.contact .details a {
    color: white;
    text-decoration: none;
}

.contact .form .thanks, .contact .form.submitted form {
    display: none;
}
.contact .form.submitted .thanks {
    display: block;
}
@media (max-width: 1199px) {
    .navbar-collapse {
        background-image: url("../images/small-menu-background.svg");
        background: var(--green);
        background-position: bottom center;
        background-size: cover;
        color: white;
        padding:2rem;
        font-size: 2.3rem;
        height: calc(100vh - 1.625rem - (var(--logo-height) / 2));
    }
    .navbar {
        padding-bottom: 0;
    }
    .nav-item {
        margin-left: .5rem;
        margin-right: .5rem;
    }
    a.nav-link {
        color: white;
    }
    a.nav-link.active {
        color: white;
    }
    section {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .how-card {
        width: 100%;
        max-width: 250px;
    }
    @media screen {
    .feature {
        height: calc(100vh - 1.625rem - (var(--logo-height) / 2));
    }
}
    .feature-card {
        margin: .8rem .4rem;
    }
    .contact-grid {
        grid-template-columns: auto;
        grid-template-areas:
            "header" "text" "form" "details" "spacer";
    }
    .contact .spacer {
        height: 30rem;
    }
}
@media (max-width: 767px) {
    .how-card {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
    .how-card img {
        width: 40%;
    }
    .navbar-brand img {
        height: calc(var(--logo-height) / 2);
    }
}

@media print {
    section {
        padding-top: 3rem;
        padding-bottom: 1rem;
    }
    .feature {
        height: 50%;
    }
        .no-print {
        display: none ! important;
    }
    .page-break-after {
        page-break-after: always;
    }
    .integration-text {
        flex: 1 1 auto;
    }
    .integrates-with-section {
        margin-top: 5rem;
    }
    .how-it-works-section {
        margin-top: 5rem;
    }
    .modules {
        height: 100%;
    }
}